<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot'
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #EBF6FFBF;"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <!--<a href="#" class="text-center mb-10">
            <img src="media/klik/logo.png" class="max-h-70px" alt=""/>
          </a>-->
           <div
				  class="d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center" style="background-size: 50%;height: 90px;"
				  :style="{ backgroundImage: `url(${LogoImage})` }"
			></div>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-top bgi-position-x-center" style="background-size: 100%;"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
           
              <div class="pb-13 pt-lg-0 pt-5">
                <!--<h3 class="font-weight-bolder text-dark font-size-h4 font-size-h3-lg" >
                  Obtenez votre URL de boutique personnalisée<br>Aucune mode de paiement requise
                </h3>-->
                <!--<span class="text-muted font-weight-bold font-size-h4"
                  >New Here?
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >Create an Account</a
                  ></span
                >-->
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark"
                  >{{ $t('AUTH.MSG_EMAIL_ADDRESS_PHONE_NUMBER')}}</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="email"
					v-bind:value="email" v-on:change="email = $event.target.value"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >{{ $t('AUTH.MSG_PASSWORD')}}</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >{{ $t('AUTH.MSG_FORGET_PASSWORD')}}</a
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    autocomplete="off"
					v-bind:value="password" v-on:change="password = $event.target.value"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button ref="kt_login_signin_submit" class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3 w-100"  @click="onSubmit()">
					{{ $t('AUTH.MSG_LOGIN')}}
                </button>
				
				<div class="ui-line"><div class="ui-line-content">{{ $t('AUTH.MSG_NO_ACCOUNT')}}</div></div>
                
				<button type="button" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg w-100"  @click="SignUpRedirect()">
                  {{ $t('AUTH.MSG_CREATE_ACCOUNT')}}
                </button>
              </div>
          </div>
          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t('AUTH.MSG_FORGET_PASSWORD')}}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t('AUTH.MSG_ENTER_EMAIL_PHONE_RESET_PASSWORD')}}
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="e-mail ou Numéro de téléphone"
                  name="email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  {{ $t('AUTH.MSG_SEND')}}
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  {{ $t('AUTH.MSG_CANCEL')}}
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"        >
          <a target="_blank" href="https://try.doken.co/" class="text-primary font-weight-bolder font-size-h5"
            >{{$t('AUTH.MSG_CONTACT')}}</a
          >
          <a target="_blank" href="https://try.doken.co/" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >{{$t('AUTH.MSG_IN_REGARDS_TO')}}</a
          >
          <a target="_blank" href="https://try.doken.co/" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >{{$t('AUTH.MSG_SUPPORT')}}</a
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
.ui-line {
    text-align: center;
    position: relative;
    top: 2px;
    padding-top: 1px;
    margin-bottom: 14px;
    line-height: 0;
	text-align: center;
}
.ui-line::after {
    content: "";
    width: 100%;
    background-color: transparent;
    display: block;
    height: 1px;
    border-top: 1px solid #e7e7e7;
    position: absolute;
    top: 50%;
    z-index: 1;
}
.ui-line .ui-line-content {
    line-height: 1;
    font-size: 12px;
    color: #767676;
    font-weight: 400;
    z-index: 2;
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 0 8px 0 7px;
}
</style>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "login-1",
   data: () => ({
		state: "signin",
		password: "",
		email: "",
  }),
    computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/klik/Easy-Shopping.png"
      );
    },
	LogoImage() {
      return (
        process.env.BASE_URL + "media/klik/logo.png"
      );
    }
  },
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
 // window.location.pathname
	},
  methods: {
    showForm(type) {
		this.state = type;
	},
    SignUpRedirect() {
		this.$router.push('/register');
	},
    onSubmit() {
		var email = this.email;
		console.log(this.email);
		var password = this.password;
		var data = {email:email,password:password};

		axios.post("/login",data).then((response) => {
			if(response.data.login == "error"){
				Swal.fire({
					title: this.$t('APP_NAME'),
					text: this.$t('AUTH.MSG_ERROR_LOGIN_PASSWORD'),
					icon: "error",
					confirmButtonClass: "btn btn-secondary",
					heightAuto: false
				}); 
			}else if(response.data.login == false){
				Swal.fire({
					title: this.$t('APP_NAME'),
					text: this.$t('AUTH.MSG_ERROR_LOGIN_PASSWORD'),
					icon: "error",
					confirmButtonClass: "btn btn-secondary",
					heightAuto: false
				}); 
			}else{
				localStorage.setItem('CustomerData', JSON.stringify(response.data.login));
				localStorage.setItem('CustomerSettings', JSON.stringify(response.data.settings));
				localStorage.setItem('TemplateSettings', response.data.template);
				localStorage.setItem('isAuthenticated', true);
				this.$router.push('/dashboard');
			}
		}).catch((errors) => {    
			Swal.fire({
				title: this.$t('APP_NAME'),
				text: this.$t('AUTH.MSG_ERROR_HAS_OCCURRED'),
				icon: "info",
				confirmButtonClass: "btn btn-secondary",
				heightAuto: false
			});
			console.log(errors);
		});
    },
  }
};
</script>
